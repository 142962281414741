import * as Sentry from "@sentry/react";
import { ErrorSign } from "@/components/error-sign";
import { useState, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import ascenda_logo from "@/images/ascenda_logo.svg";
import { z } from "zod";
import { ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { trackEvent, santizeHTTPError } from "@/lib/utils";
import { AscendaButton } from "@/components/ascenda-button";
import { GenericEmailDomain, DomainUrlPattern } from "@/lib/constants";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { ErrorDialog } from "@/components/error-dialog";
import { ApiService } from "@/lib/api-service";
import { HTTPError } from "ky";
import { FormContainder } from "@/components/form-container";
import { EmailsCard } from "@/components/emails-card";
import { CaptchaToken } from "@/lib/types";
import { isURL } from "validator"

const apiService = new ApiService();

const FormSchema = z.object({
  tenant_name: z
    .string({ required_error: "Please enter your company name" })
    .min(1, { message: "Please enter your company name" }),
  tenant_domain: z
    .string({ required_error: "Please enter your company website" })
    .min(1, { message: "Please enter your company website" })
    .refine(
      (data) => {
        if (!isURL(data, { allow_query_components: false  })) { return false }
        if (URL.canParse(data)) { return ["", "/"].includes(new URL(data).pathname) }
        return true
      },
      {
        message: "Please enter a valid domain",
      },
    ),
  email: z
    .string({ required_error: "Please enter your email address" })
    .min(1, { message: "Please enter your email address" })
    .email({ message: "Please enter a valid email address" })
    .refine(
      (data) => {
        const emailDomain = data.split("@")[1];
        return !GenericEmailDomain.includes(emailDomain);
      },
      {
        message: "Please use a company email address",
      },
    ),
});

export function Demo() {
  const recaptchaRef = useRef();
  const [showEmailList, setShowEmailList] = useState(false);
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const form = useForm<z.infer<typeof FormSchema>>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(FormSchema),
  });
  const { mutateAsync: createDemoTenant } = useMutation({
    mutationFn: (data: z.infer<typeof FormSchema> & CaptchaToken) => {
      // Note: BE side could take as long as 35 seconds due to
      // UI inference API calls and user activation delay
      return apiService.post("/api/demo", data, { timeout: 35_000 });
    },
  });

  async function onSubmit(formData: z.infer<typeof FormSchema>) {
    setLoading(true);

    let captcha_token = null;
    try {
      captcha_token = await recaptchaRef.current.executeAsync();
    } catch (error) {
      setLoading(false);
      setOpenDialog(true);
      recaptchaRef.current.reset();
      Sentry.captureException(error);
      return;
    }

    trackEvent("demo_creation_submit", {
      company_name: formData.tenant_name,
      company_domain: formData.tenant_domain,
    });

    try {
      if (URL.canParse(formData.tenant_domain)) {
        formData.tenant_domain = (new URL(formData.tenant_domain)).host
      }

      const response = await createDemoTenant({ ...formData, captcha_token });
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      setServerError("");

      const iframe: HTMLIFrameElement | null =
        document.querySelector("#rc-iframe");

      if (iframe) {
        const uiSettings = response.uiSettings ?? {}
        iframe.contentWindow?.postMessage(
          {
            type: "tenant_hub_custom_ui_configs",
            payload: uiSettings,
          },
          import.meta.env.VITE_RC_URL,
        );
      }

      setLoading(false);
      window.location.assign(response.redirectUri);
    } catch (error) {
      if (error instanceof HTTPError && error.response.status != 500) {
        const errorJson = await error.response.json();
        const errorObj = errorJson.errors[0];
        const sanitizedError = santizeHTTPError(errorObj);
        setServerError(sanitizedError.message);
      } else {
        setOpenDialog(true);
      }

      setLoading(false);
      recaptchaRef.current.reset();
      Sentry.captureException(error);
    }
  }

  return (
    <FormContainder>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITEKEY}
      />
      <div className="m-auto flex flex-col justify-center max-lg:w-10/12 w-1/2">
        <ErrorSign
          className="my-8"
          hidden={serverError === ""}
          error_message={serverError}
        />
        <div className="w-full hidden max-lg:block mb-14 mt-4">
          <img src={ascenda_logo} className="w-32" alt="" />
        </div>
        <div className="text-destructive pb-2 text-sm">TRY IT NOW</div>
        <div className="text-4xl font-bold text-primary pb-6 pr-6">{`Experience Ascenda's rewards program`}</div>
        <div className="text-primary pb-6">
          {`Simply enter your basic details and we'll ship up a demo for you to explore.`}
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-4 w-full"
          >
            <FormField
              control={form.control}
              name="tenant_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>COMPANY NAME</FormLabel>
                  <FormControl>
                    <Input
                      disabled={loading}
                      type="text"
                      placeholder="Central Perk Inc."
                      error={form.formState.errors.tenant_name}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="tenant_domain"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>COMPANY WEBSITE</FormLabel>
                  <FormControl>
                    <Input
                      disabled={loading}
                      type="text"
                      placeholder="www.centralperk.com"
                      error={form.formState.errors.tenant_domain}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="pb-4">
                  <FormLabel>YOUR COMPANY EMAIL ADDRESS</FormLabel>
                  <FormControl>
                    <Input
                      data-sentry-mask
                      disabled={loading}
                      type="text"
                      placeholder="ross.geller@centralperk.com"
                      error={form.formState.errors.email}
                      onFocus={() => {
                        setShowEmailList(true);
                      }}
                      {...field}
                      {...form.register("email", {
                        onBlur: () => {
                          setShowEmailList(false);
                        },
                      })}
                    />
                  </FormControl>
                  <FormMessage />
                  <EmailsCard show={showEmailList} />
                </FormItem>
              )}
            />
            <AscendaButton
              content="Generate demo"
              loading={loading}
              rightIcon={
                <div className="ml-2">
                  <ArrowRight className="h-4 w-4" />
                </div>
              }
            />
          </form>
        </Form>
      </div>
      <ErrorDialog
        title="Oh no, something went wrong..."
        content="An unexpected error has occured, sorry about that. We've been notified and will get started on a fix."
        open={openDialog}
        onOpenChange={setOpenDialog}
      />
      {import.meta.env.VITE_RC_URL && (
        <iframe
          id="rc-iframe"
          src={import.meta.env.VITE_RC_URL}
          title="RC iframe"
          style={{ position: "absolute", width: 0, height: 0, border: 0 }}
          width="0"
          height="0"
        />
      )}
    </FormContainder>
  );
}
